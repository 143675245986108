@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html, body {
    @apply font-cursive relative flex h-full items-center justify-center bg-storm
  }
  .svg {
    @apply block
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* figcaption.station {
    font-family: 'Raleway', serif;
    font-size: 14px;
    margin-top: 15px;
    text-transform: uppercase;
    color: white;
} */

figcaption.station.loader {
    font-family: 'Raleway', serif;
    font-size: 14px;
    margin-top: 15px;
    color: white;
    background-color: #1a275c;
}

/* figcaption.sensor {
    font-family: 'Raleway', serif;
    font-size: 14px;
    margin-top: 15px;
    color: white;
} */

figcaption.sensor.loader {
    font-family: 'Raleway', serif;
    font-size: 14px;
    margin-top: 15px;
    color: white;
    background-color: #1a275c;
}

span {
    transition: color .4s ease;
}

span.green-value {
    color: #00bc9b;
}

span.blue-value {
    color: #5eaefd;
}

span.light-red-value {
    color: #fd695e;
}

span.red-value {
    color: red;
}